<template>
  <div class="post-msg-box usually-box">
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <ul class="post-sort">
            <li v-for="(item, index) in postSort" :key="index">
              <div class="name">
                {{item.name}}
              </div>
              <div class="details">
                <span v-for="(detItem, detIndex) in item.list" :key="detIndex" :class="{'on':form[item.bindForm] === detItem.code}" @click="changeMenu(item.bindForm, detItem)">{{detItem.name}}</span>
              </div>
            </li>
          </ul>
        </div>
      </template>
      <div class="post-table-box">
        <el-table :data="postTable.list" stripe style="width: 100%" size="large" :row-style="tableStyle" @cell-click="toPage">
          <el-table-column prop="name" label="职位名称" />
          <el-table-column prop="place" label="工作地点" />
          <el-table-column prop="position" label="职位类别" />
          <el-table-column prop="recruitNum" label="招聘人数" />
          <el-table-column prop="releaseTime" width="200" label="发布日期" />
          <template #empty>
            <div style="padding: 30px; text-align: center; color: #999;">
              没有数据
            </div>
          </template>
        </el-table>
        <div class="page-box">
          <el-pagination background :page-size="10" layout="prev, pager, next" :total="total" />
        </div>
      </div>
    </el-card>
    <div class="follow">
      <img :src="websiteMsg.weChatImg" alt="">
      <p>关注微信</p>
      <p>获取最新招聘信息</p>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, computed, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import { getSelectZqRecruit } from '@/api/post'
import { useWebsite } from '@/pinia/index'
import { storeToRefs } from 'pinia'

const { websiteMsg } = storeToRefs(useWebsite())
const route = reactive(useRouter())
function toPage(row) {
  sessionStorage.setItem('jobItem', JSON.stringify(row))
  const url = '/post/details'
  route.push(url)
}

const form = reactive({
  place: null,
  position: 1,
})

const total = ref(1)

const postSort = reactive([
  {
    name: '工作地点',
    bindForm: 'place',
    list: [
      {
        name: '全部',
        code: null
      },
      {
        name: '长沙市',
        code: 1
      }
    ]
  },
  {
    name: '职位类别',
    bindForm: 'position',
    list: [
      {
        name: '全部',
        code: null
      },
      {
        name: '开发',
        code: 1,
      },
      {
        name: '测试',
        code: 2,
      },
      {
        name: '产品',
        code: 3,
      },
    ]
  },
  // {
  //   name: '工作地点',
  //   list: [
  //     {
  //       name: '长沙市',
  //     }
  //   ]
  // },
])

let postTable = reactive({
  list: []
})


const tableStyle = {
  fontSize: "16px"
}

function getPosition() {
  getSelectZqRecruit(form).then(res => {
    postTable.list = res.data.data
    console.log(postTable)
    total.value = res.data.total;
  })
}

function changeMenu(filed, item) {
  form[filed] = item.code
  getPosition()
}

onBeforeMount(() => {
  getPosition()
})


</script>
<style lang='scss' scoped>
.post-msg-box {
  display: flex;
  padding-top: 100px;
  padding-bottom: 80px;
  background-color: $contactColor;
}
.follow {
  margin: 100px 0 0 20px;
  font-size: 18px;
  line-height: 1.8em;
  text-align: center;
  color: #fff;
  > img {
    margin-bottom: 20px;
  }
}
.box-card {
  flex: 1;
  margin-top: $headerHeight;
}
.card-header {
  .post-sort {
    font-size: 18px;
    > li {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      cursor: pointer;
      .name {
        flex-shrink: 0;
        width: 100px;
        &::after {
          content: "：";
        }
      }
      .details {
        padding-top: 1px;
        font-size: 16px;
        > span {
          margin: 0 15px 10px 0;
          color: #999;
        }
        .on {
          color: $hoverColor;
        }
      }
    }
  }
}
.page-box {
  margin-top: 30px;
  padding: 20px 0;
}
</style>